import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	ImportExample,
	Header,
	Code,
} from "../../../../../src/docs";
import { useSingleFilter } from "../filters/filters";
import { TableToolbar } from "../table-toolbar/table-toolbar";
import { SortField } from "../column-header/column-header";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { ListTable, ListTableProps, sortSelectPropsHelper } from "./list-table";

export const Meta: DocPageMeta = {
	category: "Tables and lists",
	title: "List table",
	notepad: "",
};

const items = [
	{ id: 1, dish: "Grilled Salmon", calories: "500 calories", protein: "30 g protein" },
	{ id: 2, dish: "Veggie Lasagne", calories: "350 calories", protein: "12 g protein" },
	{ id: 3, dish: "Beef Stir-Fry", calories: "450 calories", protein: "25 g protein" },
];

const sortOptions: {
	label: string;
	property: Extract<keyof typeof items[0], string>;
	direction: "asc" | "desc";
}[] = [
	{ label: "Dish (ascending)", property: "dish", direction: "asc" },
	{ label: "Dish (descending)", property: "dish", direction: "desc" },
];

const translations = {
	itemsFoundLabel: (count: number) =>
		count === 1 ? `${count} item found` : `${count} items found`,
	sortByLabel: "Sort by",
	sortedByLabel: `Sorted by ${sortOptions[0].label}`,
	loadMoreLabel: (count: number) => `Load ${count} more`,
	loadAllLabel: "Load all",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="List table"
			subTitle="Present structured data for quick scanning and essential details, ideal for related items with concise summaries."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={ListTable} />
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					<b>Composition</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							<b>Background</b>: A subtle background color can visually separate the list table from
							surrounding content. Use a neutral color that doesn't compete with the content.
						</>,
						<>
							<b>Item Count</b> (Optional): Display the total number of items in the list,
							especially for filtered or large datasets. Add context when applicable (e.g.,
							"Filtered by: Active").
						</>,
						<>
							<b>Sorting Indicator</b> (Optional): Clearly indicate the active sorting criteria
							(e.g., "Sorted by Name (Ascending)"). This helps users understand how the list is
							organized.
						</>,
						<>
							<b>Rows and Cells</b> (Optional): Rows represent individual data items, while cells
							hold specific pieces of information within a row.
						</>,
					]}
				/>
				<Header.H3>Basic usage</Header.H3>
				<Paragraph>
					<b>Use Cases</b>:
				</Paragraph>
				<Ul
					items={[
						"Surface essential information quickly (e.g., site names, scores, policy summaries).",
						"Offer an overview of data, acting as an entry point to more details. (e.g., Core wins).",
						"Enable users to scan for patterns and insights.",
					]}
				/>
				<Paragraph>
					<b>Best Practices</b>:
				</Paragraph>
				<Ul
					items={[
						"Focus on the most important data points, keeping information density low to avoid overwhelming users.",
						"Consider embedding list tables within cards or other primary content areas to provide context.",
						" Keep additional actions (shortcuts, CTAs) to a maximum of two per row to maintain visual clarity.",
					]}
				/>
				<Example fn={BasicUsage} />
				<Header.H3>Usage with pagination</Header.H3>
				<Paragraph>
					Use for large datasets. Provide clear controls and indicate the total number of pages.
					Consider offering options to adjust items per page.
				</Paragraph>
				<Example fn={UsageWithPagination} />
				<Header.H3>Usage with load more buttons</Header.H3>
				<Paragraph>
					Alternative to pagination when space is limited or the total item count is unknown. Ensure
					the button is visible and provide loading feedback.
				</Paragraph>
				<Example fn={UsageWithLoadMoreButtons} />
				<Header.H3>Usage with sorting</Header.H3>
				<Paragraph>
					Allow users to reorder the list. Use a{" "}
					<GatsbyLink to="/lab/components/Forms and input/Select">Select</GatsbyLink> for options
					and indicate the current sorting state.
				</Paragraph>
				<Paragraph>
					When using sorted lists, you must provide the <Code>sortSelect</Code> and{" "}
					<Code>sort</Code> properties as well as the <Code>colums[].header.property</Code> value of
					sortable columns.
				</Paragraph>
				<Example fn={UsageWithSorting} />
				<Header.H3>Usage with table toolbar</Header.H3>
				<Paragraph>
					Provide a centralized space for actions that apply to the entire table (filtering, bulk
					actions, customization). Keep it uncluttered and visually distinct.
				</Paragraph>
				<Example fn={UsageWithTableToolbar} />
				<Header.H3>Usage without filter, sort or count header</Header.H3>
				<Paragraph>
					For simple, static lists where these features aren't needed. Ensure the list is
					self-explanatory and visually organized. Consider a card header or title for context.
				</Paragraph>
				<Example fn={UsageWithoutHeader} />
				<Header.H3>Loading state</Header.H3>
				<Paragraph>
					Use a <GatsbyLink to="/lib/components/Feedback/spinner">Spinner</GatsbyLink> and/or brief
					message to indicate data is being fetched.
				</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:
				</Paragraph>
				<Ul
					items={[
						"Use a Spinner component to indicate the loading state.",
						"Place the loading indicator in the center of the list table area.",
						"Consider adding a brief message explaining the delay.",
					]}
				/>
				<Example fn={UsageWithLoadingState} />
				<Header.H3>No data state</Header.H3>
				<Paragraph>
					Communicate clearly that no data matches the criteria. Offer suggestions or actions to
					help the user.
				</Paragraph>
				<Paragraph>
					<b>Best Practices</b>:
				</Paragraph>
				<Ul
					items={[
						<>
							Use a visually distinct "empty state" design, read more in{" "}
							<GatsbyLink to="/lab/components/Visuals/Empty State">Empty State</GatsbyLink>.
						</>,
						"Provide a clear, concise message explaining why no data is available.",
						<>Offer relevant actions, such as "Try a different search" or "Create a new item".</>,
						"Avoid blaming the user or using negative language.",
					]}
				/>
				<Paragraph>
					<b>Consideration:</b>
				</Paragraph>
				<Paragraph>
					The <Code>noDataState</Code> is used to indicate that no data is available for display. An{" "}
					<GatsbyLink to="/lab/components/Visuals/Empty State">Empty State</GatsbyLink> component
					with the <Code>type</Code> "reassure" and default heading is shown, but it can be
					overridden by another type with custom text. For guidelines please refer to the{" "}
					<GatsbyLink to="/lab/components/Visuals/Empty State">Empty State</GatsbyLink> component.
				</Paragraph>
				<Paragraph>
					To avoid confusion, in cases where the user has resolved the issues, explain why the data
					cannot be displayed. E.g. "Accessibility issues have been resolved".
				</Paragraph>
				<Example fn={UsageWithNoDataState} />
				<Header.H2>Properties</Header.H2>
				<Knobs<ListTableProps<typeof items[0], typeof sortOptions[0]>>
					component={ListTable}
					initialProps={{
						items: items,
						columns: [
							{
								header: { content: "Dish" },
								render: (item) => <div>{item.dish}</div>,
								options: { isKeyColumn: true },
							},
							{ header: { content: "Calories" }, render: (item) => <div>{item.calories}</div> },
							{ header: { content: "Protein" }, render: (item) => <div>{item.protein}</div> },
						],
						loading: false,
						...translations,
					}}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>ListTable</Code> when
					</Paragraph>
					<Ul
						items={[
							"Display structured overview of data with clear item relationships.",
							"Navigate to specific items within a hierarchy.",
							"Quick access to individual items.",
							"Visual elements to enhance presentation (charts, icons, illustrations).",
						]}
					/>
					<Header.H4>Placement</Header.H4>
					<Paragraph>
						<Code>ListTable</Code> is typically used in the following places:
					</Paragraph>
					<Ul
						items={[
							<>
								User-generated content listings (e.g.,{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FSettings%2FSites%2Fv2%3Flang%3Den-US">
									Site
								</a>
								,{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FPolicy%2FContentPolicies%2FIndex%2F1%2FChangedDate%2FDesc%3FpinnedOnly%3Dfalse%26pageSize%3D200%26policies-tab%3D0%26lang%3Den-US">
									My policy
								</a>
								).
							</>,
							<>
								Issue catalogs (e.g.,{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FInsights%2F446001%2FOverview%2FIndex%3FselectedFunnel%3D0%26lang%3Den-US">
									Core wins
								</a>
								).
							</>,
						]}
					/>
					<Header.H4>Style</Header.H4>
					<Ul
						items={[
							<>
								<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
								typography, and spacing. If you are not using a component from Fancy, match the
								styling of your <Code>ListTable</Code> to existing components for visual
								consistency.
							</>,
							<>
								Always use a header when embedding a ListTable within a{" "}
								<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>.
							</>,
							<>
								Anchor supporting visuals (thumbnails, etc.) along the row's edge for better
								scalability.
							</>,
							<>
								Incorporate bulk actions like checkboxes or a "mark as favorite" starred button
								(first column).
							</>,
							<>
								Labels:{" "}
								<Ul
									items={[
										"Avoid long text in expandable containers.",
										"Prioritize key information over metadata.",
										"Ensure each cell's content is self-explanatory to eliminate the need for column headers.",
									]}
								/>
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								Highly detailed information or complex relationships. Use{" "}
								<GatsbyLink to="/lab/components/Tables and lists/Table">Table</GatsbyLink> instead.
							</>,
							<>
								Primarily numerical data requiring calculations. Use{" "}
								<GatsbyLink to="/lab/components/Tables and lists/Table">Table</GatsbyLink> instead.
							</>,
							<>
								Extremely large datasets. Use{" "}
								<GatsbyLink to="/lab/components/Tables and lists/Table">Table</GatsbyLink> instead.
							</>,
							<>
								Identifying trends. Use{" "}
								<GatsbyLink to="/lab/components/Data Visualization/Chart">Chart</GatsbyLink>{" "}
								instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Header.H4>For designers</Header.H4>
					<Ul
						items={[
							"Ensure sufficient contrast, clear focus indicators, and descriptive labels/alt text for visual elements.",
						]}
					/>
					<Header.H4>For developers</Header.H4>
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul
						items={[
							"Use concise, descriptive labels for cells.",
							"Follow sentence case conventions. Be mindful of localization.",
							"Maintain consistency in decimal usage (e.g., one decimal places). Do not use 1 decimal in one row and 2 in another.",
							<>
								Avoid punctuation within list unless data contains numbers and units. Follow{" "}
								<a href="https://fancy.siteimprove.com/Writing/grammar-and-mechanics">
									Grammar and mechanics
								</a>
								.
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => (
	<ListTable
		items={sortItems(items, { property: "dish", direction: "asc" })}
		columns={[
			{
				header: { content: "Dish" },
				render: (item) => <div>{item.dish}</div>,
				options: { isKeyColumn: true },
			},
			{ header: { content: "Calories" }, render: (item) => <div>{item.calories}</div> },
			{ header: { content: "Protein" }, render: (item) => <div>{item.protein}</div> },
		]}
		loading={false}
		caption="Some table data in a list"
		{...translations}
	/>
);

const UsageWithPagination = () => {
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(2);
	const visibleItems = sortItems(items, { property: "dish", direction: "asc" }).slice(
		(page - 1) * pageSize,
		page * pageSize
	);

	return (
		<ListTable
			items={visibleItems}
			columns={[
				{
					header: { content: "Dish" },
					render: (item) => <div>{item.dish}</div>,
					options: { isKeyColumn: true },
				},
				{ header: { content: "Calories" }, render: (item) => <div>{item.calories}</div> },
				{ header: { content: "Protein" }, render: (item) => <div>{item.protein}</div> },
			]}
			loading={false}
			caption="Some table data in a list"
			pagination={{
				total: items.length,
				count: pageSize,
				page: page,
				setPage: setPage,
				pageSize: pageSize,
				setPageSize: setPageSize,
				cancelLabel: "Cancel",
				confirmLabel: "Confirm",
				firstLabel: "First",
				prevLabel: "Previous",
				nextLabel: "Next",
				lastLabel: "Last",
				pagingInfoLabel: (startIdx: number, endIdx: number, total: number) =>
					`${startIdx} - ${endIdx} of ${total} items`,
				pageLabel: "Page",
				pageXofYLabel: (current: number, total: number) => `Page ${current} of ${total}`,
				pageSizeSelectionLabel: (pageSize: number) => `${pageSize} items`,
				pageSizeSelectorPrefix: "Show",
				pageSizeSelectorPostfix: "per page",
				pageSizeLabel: "Items per page",
				defaultError: "Default pagination error",
				wholeNumberError: "Must be a whole number",
				outOfBoundsError: (total: number) => `Enter a number between 1 and ${total}`,
			}}
			{...translations}
		/>
	);
};

const UsageWithLoadMoreButtons = () => (
	<ListTable
		sort={{ property: "dish", direction: "asc" }}
		items={sortItems(items, { property: "dish", direction: "asc" })}
		columns={[
			{
				header: { content: "Dish", property: "dish" },
				render: (item) => <div>{item.dish}</div>,
				options: { isKeyColumn: true },
			},
			{ header: { content: "Calories" }, render: (item) => <div>{item.calories}</div> },
			{ header: { content: "Protein" }, render: (item) => <div>{item.protein}</div> },
		]}
		loading={false}
		caption="Some table data in a list"
		loadMoreCount={1}
		showLoadAll
		{...translations}
	/>
);

const UsageWithSorting = () => {
	const [sort, setSort] = useState(sortOptions[0]);
	return (
		<ListTable
			items={sortItems(items, sort)}
			columns={[
				{
					header: { content: "Dish", property: "dish" },
					render: (item) => <div>{item.dish}</div>,
					options: { isKeyColumn: true },
				},
				{ header: { content: "Calories" }, render: (item) => <div>{item.calories}</div> },
				{ header: { content: "Protein" }, render: (item) => <div>{item.protein}</div> },
			]}
			loading={false}
			sort={sort}
			sortSelect={sortSelectPropsHelper(
				"Some a11y label",
				sortOptions,
				sort,
				(property, direction) =>
					setSort(
						sortOptions.find((x) => x.property === property && x.direction === direction) ||
							sortOptions[0]
					)
			)}
			caption="Some table data in a list"
			{...translations}
		/>
	);
};

const UsageWithTableToolbar = () => {
	type Calories = { id: number; name: string };
	const caloriesAmount: Calories[] = [
		{ id: 1, name: "All calories" },
		{ id: 2, name: "Less than 500 calories" },
		{ id: 3, name: "Less than 400 calories" },
	];
	const [calories, setCalories] = useState<Calories | undefined>(caloriesAmount[0]);

	const onChange = (newValue: Calories | undefined) => {
		console.log("Filter changed, calling API with new calories count", newValue);
		setCalories(newValue);
	};

	const [filterButton, activeFilters] = useSingleFilter(calories, onChange, {
		label: "Calories",
		stringify: (calories) => calories?.name,
		items: caloriesAmount.map((calories) => ({ title: calories.name, value: calories })),
		compareFn: (a, b) => a.id === b.id,
		defaultOption: caloriesAmount[0],
	});

	const sort: SortField<typeof items[0]> = {
		property: "dish",
		direction: "asc",
	};

	const displayedItems =
		calories?.id === 1
			? items
			: items.filter((x) => {
					const caloriesMatch = x.calories.match(/\d+/);
					if (!caloriesMatch) return false;
					const caloriesValue = Number(caloriesMatch[0]);
					return calories?.id === 2 ? caloriesValue < 500 : caloriesValue < 400;
			  });
	return (
		<>
			<TableToolbar filter={filterButton} activeFilters={activeFilters} />
			<ListTable
				items={sortItems(displayedItems, { property: sort.property, direction: sort.direction })}
				columns={[
					{
						header: { content: "Dish", property: "dish" },
						render: (displayedItems) => <div>{displayedItems.dish}</div>,
						options: { isKeyColumn: true },
					},
					{
						header: { content: "Calories" },
						render: (displayedItems) => <div>{displayedItems.calories}</div>,
					},
					{
						header: { content: "Protein" },
						render: (displayedItems) => <div>{displayedItems.protein}</div>,
					},
				]}
				sort={sort}
				loading={false}
				caption="Some table data in a list"
				{...translations}
			/>
		</>
	);
};

const UsageWithoutHeader = () => {
	return (
		<ListTable
			items={sortItems(items, { property: "dish", direction: "asc" })}
			columns={[
				{
					header: { content: "Dish" },
					render: (item) => <div>{item.dish}</div>,
					options: { isKeyColumn: true },
				},
				{ header: { content: "Calories" }, render: (item) => <div>{item.calories}</div> },
				{ header: { content: "Protein" }, render: (item) => <div>{item.protein}</div> },
			]}
			loading={false}
			caption="Some table data in a list"
			loadMoreLabel={(count: number) => `Load ${count} more`}
			loadAllLabel="Load all"
			// leave out the "itemsFoundLabel", "sortByLabel" & "sortedByLabel" props to avoid the header
		/>
	);
};

const UsageWithLoadingState = () => (
	<ListTable
		items={sortItems([] as typeof items, { property: "dish", direction: "asc" })}
		columns={[
			{
				header: { content: "Dish" },
				render: (item) => <div>{item.dish}</div>,
				options: { isKeyColumn: true },
			},
			{ header: { content: "Calories" }, render: (item) => <div>{item.calories}</div> },
			{ header: { content: "Protein" }, render: (item) => <div>{item.protein}</div> },
		]}
		loading={true}
		caption="Some table data in a list"
		{...translations}
	/>
);

const UsageWithNoDataState = () => (
	<ListTable
		items={sortItems([] as typeof items, { property: "dish", direction: "asc" })}
		columns={[
			{
				header: { content: "Dish" },
				render: (item) => <div>{item.dish}</div>,
				options: { isKeyColumn: true },
			},
			{ header: { content: "Calories" }, render: (item) => <div>{item.calories}</div> },
			{ header: { content: "Protein" }, render: (item) => <div>{item.protein}</div> },
		]}
		loading={false}
		caption="Some table data in a list"
		{...translations}
	/>
);

function sortItems<TDto>(items: TDto[], sort: SortField<TDto>): TDto[] {
	return items.sort((a, b) => {
		const first = sort.direction === "asc" ? a : b;
		const second = sort.direction === "asc" ? b : a;
		const v1 = first[sort.property];
		const v2 = second[sort.property];
		if (typeof v1 === "string" && typeof v2 === "string") {
			return v1.localeCompare(v2, undefined, {
				numeric: true,
				sensitivity: "base",
			});
		}

		return v1 > v2 ? 1 : v2 > v1 ? -1 : 0;
	});
}
